// Fonts -----------------------------------------------------------------------
$root-font-family: 'Inter', sans-serif;
$root-font-size: 100%;
$root-line-height: 1.5;
$root-font-weight: 400;

// Breakpoint ------------------------------------------------------------------
$breakpoint-max: 1680px;
$breakpoint-large: 1080px;
$breakpoint-medium: 900px;
$breakpoint-mobile: 900px;
$breakpoint-small: 768px;
$breakpoint-tiny: 350px;

// Page sizing -----------------------------------------------------------------
$content-width: 800px;
$text-width: 600px;
$modal-width: 400px;
$page-width: 1200px;
$form-width: 310px;

// Border radius ---------------------------------------------------------------
$border-radius-small: 5px;
$border-radius: 15px;
$border-radius-large: 25px;
$border-radius-full: 1000px;

// Colors ----------------------------------------------------------------------
$color-black: #000000;
$color-darker-gray: #3b3b3b;
$color-dark-gray: #8d8d8d;
$color-gray: #cdcdcd;
$color-light-gray: #ededed;
$color-lighter-gray: #f8f8f8;
$color-white: #ffffff;

$color-blue: #0500ff;
$color-glass: #21242b9d;

$color-text: $color-black;
$color-background: $color-white;

// Effects ----------------------------------------------------------------------
$box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
$blur: blur(10px);

// Spacing ---------------------------------------------------------------------
$spacing-xx-small: 4px;
$spacing-x-small: 8px;
$spacing-small: 16px;
$spacing-medium: 24px;
$spacing-large: 32px;
$spacing-x-large: 48px;
$spacing-xx-large: 56px;

// Font-size -------------------------------------------------------------------
$font-size-x-small: 10px;
$font-size-small: 12px;
$font-size-basis: 16px;
$font-size-medium: 20px;
$font-size-large: 24px;
$font-size-x-large: 32px;
$font-size-xx-large: 40px;

@mixin hero($weight: bold) {
  font-size: 72px;
  font-weight: $weight;

  line-height: 80px;
  @media only screen and (max-width: $breakpoint-small) {
    font-size: 56px;

    line-height: 64px;
  }
}
@mixin h1($weight: bold) {
  font-size: 56px;
  font-weight: bold;

  line-height: 64px;
  @media only screen and (max-width: $breakpoint-small) {
    font-size: 48px;

    line-height: 56px;
  }
}
@mixin h2($weight: bold) {
  font-size: 48px;
  font-weight: $weight;

  line-height: 56px;
  @media only screen and (max-width: $breakpoint-small) {
    font-size: $font-size-xx-large;

    line-height: 48px;
  }
}
@mixin h3($weight: bold) {
  font-size: $font-size-xx-large;
  font-weight: $weight;

  line-height: 48px;
  @media only screen and (max-width: $breakpoint-small) {
    font-size: $font-size-x-large;

    line-height: 40px;
  }
}
@mixin h4($weight: bold) {
  font-size: $font-size-x-large;
  font-weight: $weight;

  line-height: 40px;
  @media only screen and (max-width: $breakpoint-small) {
    font-size: $font-size-large;

    line-height: 32px;
  }
}
@mixin h5($weight: bold) {
  font-size: $font-size-large;
  font-weight: $weight;

  line-height: 32px;
  @media only screen and (max-width: $breakpoint-small) {
    font-size: $font-size-medium;

    line-height: 28px;
  }
}
@mixin h6($weight: bold) {
  font-size: $font-size-medium;
  font-weight: $weight;

  line-height: 28px;
  @media only screen and (max-width: $breakpoint-small) {
    font-size: $font-size-medium;

    line-height: 28px;
  }
}
@mixin excerpt($weight: bold) {
  font-size: $font-size-medium;
  font-weight: $weight;

  line-height: 28px;
  @media only screen and (max-width: $breakpoint-small) {
    font-size: $font-size-basis;

    line-height: 24px;
  }
}
@mixin body($weight: normal) {
  font-size: $font-size-basis;
  font-weight: $weight;
  @media only screen and (max-width: $breakpoint-medium) {
    font-size: $font-size-x-small;
  }
}
@mixin caption($weight: normal) {
  font-size: $font-size-small;
  font-weight: $weight;

  line-height: 16px;
}

// Positioning -----------------------------------------------------------------
@mixin flex($align: center, $justify: center, $direction: row, $wrap: wrap) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $justify;
  align-items: $align;
}
